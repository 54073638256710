$( document ).on('turbolinks:load', function() {	
	$(".trigger-delete").on('click', function(){
		record_id = $(this).data()["recordId"];
		model_name = $(this).data()["modelName"];
		$(".delete-btn").attr("href", "/admin/"+model_name+"/"+record_id)
		$("#delete_modal").modal('show');
	});
  
  if($('.select').length > 0) {
		$('.select').select2({
			minimumResultsForSearch: -1,
			width: '100%'
		});
	}
});

