$(document).ready(function() {

	// Bar Chart
  if ($('#bar-charts-super').data('planList') != undefined) {
    planData = eval($('#bar-charts-super').data('planList').replace(/=>/g,":"));
  	Morris.Bar({
  		element: 'bar-charts-super',
  		data: planData,
  		xkey: 'plan_name',
  		ykeys: ['subscribers'],
  		labels: ['Subscription'],
  		lineColors: ['#00c5fb'],
  		lineWidth: '3px',
  		barColors: ['#00c5fb'],
  		resize: true,
  		redraw: true
  	});
  }
  if ($('#bar-charts-store').data('orderYear') != undefined) {
    orderData = eval($('#bar-charts-store').data('orderYear').replace(/=>/g,":"));
    Morris.Bar({
      element: 'bar-charts-store',
      data: orderData,
      xkey: 'year',
      ykeys: ['count'],
      labels: ['Orders count'],
      lineColors: ['#00c5fb'],
      lineWidth: '3px',
      barColors: ['#00c5fb'],
      resize: true,
      redraw: true
    });
  }
	
	// Line Chart
	if ($('#line-charts-store').data('orderMonth') != undefined) {
    orderData = eval($('#line-charts-store').data('orderMonth').replace(/=>/g,":"));
  	Morris.Line({
  		element: 'line-charts-store',
  		data: orderData,
  		xkey: 'month',
  		ykeys: ['count'],
  		labels: ['Orders count'],
  		lineColors: ['#00c5fb'],
  		lineWidth: '3px',
  		resize: true,
  		redraw: true,
      parseTime:false
  	});
  }
		
});