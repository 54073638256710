require('./jquery.timepicker.min.js');

document.addEventListener('turbolinks:load', function() {
  $('.timepicker').timepicker({
    timeFormat: 'hh:mm p',
    interval: 15,
    dynamic: false,
    dropdown: true,
    scrollbar: true
  });
});
